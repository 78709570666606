import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

export function AuthorTooltip({ title, aboutAuthor, name }) {
  const tooltipComponent = (
    <Fragment>
      {!!title && <div className="font-weight-bold mb-0_25">{title}</div>}
      {aboutAuthor ? (
        <ContentFragment>{aboutAuthor}</ContentFragment>
      ) : (
        `${name} is an automotive journalist at Edmunds.`
      )}
    </Fragment>
  );

  return (
    <TooltipItem
      id={kebabCase(name)}
      className="top-0"
      iconClassName="medium text-gray-darker px-0_25"
      innerClassName="p-1"
      tooltipClassName="px-1"
      item={{
        placement: 'bottom',
        text: tooltipComponent,
      }}
      useUniqId
    />
  );
}

AuthorTooltip.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  aboutAuthor: PropTypes.string,
};

AuthorTooltip.defaultProps = {
  title: null,
  aboutAuthor: null,
};
