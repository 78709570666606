import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SCROLL_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { getEditorialRatingParams } from 'site-modules/shared/utils/core-page/core-highlights';
import { ScrollLink } from 'site-modules/shared/components/scroll-link/scroll-link';

import './rating-badge.scss';

export function RatingBadge({ className, editorialRating, ratingScale, isScrollLink }) {
  if (!editorialRating) {
    return null;
  }

  const isTenScale = ratingScale === 10;
  const { text, badgeClassName } = getEditorialRatingParams(editorialRating);

  const Tag = isScrollLink ? ScrollLink : 'div';
  const tagProps = isScrollLink
    ? {
        to: 'subnav-rating-scorecard',
        scrollConfig: { offset: SCROLL_OFFSET },
        'data-tracking-id': 'core_page_view_edmunds_review',
        'data-tracking-value': `${editorialRating}/${ratingScale}`,
      }
    : {};

  return (
    <Tag
      {...tagProps}
      className={classnames('rating-badge rounded-8 d-flex flex-column align-items-center', className)}
    >
      {isTenScale && <div className="font-weight-bold mb-0_25">{text}</div>}
      <div className="d-flex pos-r text-center mb-0_25">
        <svg
          className={classnames('badge-img', {
            [badgeClassName]: isTenScale,
          })}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 70 76"
          height={76}
          width={70}
          fill="none"
          preserveAspectRatio="none"
        >
          <path
            d="M4.37065 54.3724C2.23291 53.0514 0.931641 50.7177 0.931642 48.2048L0.931648 13.6473C0.931649 10.207 3.34948 7.24037 6.71907 6.54635L32.6597 1.20338C34.0239 0.922387 35.431 0.922394 36.7952 1.20338L62.7358 6.54635C66.1054 7.24037 68.5232 10.207 68.5232 13.6473L68.5232 48.2048C68.5232 50.7177 67.2219 53.0514 65.0842 54.3724L38.5384 70.7752C36.2027 72.2185 33.2521 72.2185 30.9164 70.7752L4.37065 54.3724Z"
            strokeWidth={1.5}
          />
        </svg>
        <div className="pos-a top-0 left-0 w-100 px-0_5 pt-0_5 pb-1_25">
          <div className="rating-text heading-2">{editorialRating}</div>
          <div className="small">out of {ratingScale}</div>
        </div>
      </div>
      <div
        className="bg-gray-lightest px-0_5 py-0_25 px-lg-0_25 px-xl-0_5 font-weight-medium small text-nowrap rounded-8 interaction-underline"
        style={{ letterSpacing: '1px' }}
      >
        edmunds{' '}
        <span className="font-weight-bold" style={{ letterSpacing: '3px' }}>
          TESTED
        </span>
      </div>
    </Tag>
  );
}

RatingBadge.propTypes = {
  editorialRating: PropTypes.string,
  className: PropTypes.string,
  ratingScale: PropTypes.number,
  isScrollLink: PropTypes.bool,
};

RatingBadge.defaultProps = {
  editorialRating: null,
  className: null,
  ratingScale: 10,
  isScrollLink: false,
};
