import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { EditorialReviewEntities, FirstContentEntity } from 'client/data/models/editorial-review';
import { buildWhatsNewPath, VehicleComparisonsEntities, VehicleDetailsModel } from 'client/data/models/mmy-details';
import { EditorialReviewBullets } from 'site-modules/shared/components/editorial-review-pros-cons/editorial-review-bullets';

import './editorial-highlights-lists.scss';

export const HIGHLIGHTS_NAME = {
  PROS: 'view_pros',
  CONS: 'view_cons',
  WHATS_NEW: 'view_whats_new',
  WHAT_TO_EXPECT: 'view_what_to_expect',
};

const LIST_ITEM_CLASS_NAME = 'pl-2 size-16 mb-0_5';
const CIRCLE_ICON_CLASS_NAME = 'icon-circle2 p-0_5 pt-0_5';

export function EditorialHighlightsListsUI({
  editorialReview,
  firstContent,
  whatsNewContent,
  className,
  headingClassName,
  sectionsToRender,
  isPreprod,
  HeadingTag,
}) {
  const cons = get(editorialReview, 'cons', []);
  const pros = get(editorialReview, 'pros', []);
  const hasHighlights = cons.length || pros.length || get(whatsNewContent, 'length');
  const whatToExpect = get(firstContent, 'bullets.value', []);

  const sections = hasHighlights
    ? [
        {
          name: HIGHLIGHTS_NAME.PROS,
          text: 'Pros',
          data: pros,
          iconClasses: 'icon-checkmark text-primary-darker size-12 pl-0_25 pt-0_25',
        },
        {
          name: HIGHLIGHTS_NAME.CONS,
          text: 'Cons',
          iconClasses: 'icon-cross2 text-danger size-12 pl-0_25 pt-0_25',
          data: cons,
        },
        {
          name: HIGHLIGHTS_NAME.WHATS_NEW,
          text: "What's new",
          data: whatsNewContent,
          iconClasses: CIRCLE_ICON_CLASS_NAME,
        },
      ]
    : [
        {
          name: HIGHLIGHTS_NAME.WHAT_TO_EXPECT,
          text: isPreprod ? 'What to expect' : 'What You Need to Know',
          data: whatToExpect,
          iconClasses: CIRCLE_ICON_CLASS_NAME,
        },
      ];

  const renderedSections = sections.filter(({ name, data }) => sectionsToRender.includes(name) && get(data, 'length'));

  if (!renderedSections.length) {
    return null;
  }

  return (
    <div className={classnames('editorial-highlights-lists text-gray-darker', className)}>
      {renderedSections.map(({ name, text, iconClasses, data }, index) => (
        <Fragment key={`${name}-list`}>
          <HeadingTag id={name} className={classnames(headingClassName, { 'mt-1_5': index })}>
            {text}
          </HeadingTag>
          <ul className="list-unstyled mb-0" aria-labelledby={name} data-tracking-parent={name}>
            <EditorialReviewBullets
              listItems={data}
              listItemClassName={classnames('pos-r', LIST_ITEM_CLASS_NAME)}
              listItemIconClasses={classnames('left-0', iconClasses)}
              listItemStyles={{ paddingLeft: '2rem' }}
            />
          </ul>
        </Fragment>
      ))}
    </div>
  );
}

EditorialHighlightsListsUI.propTypes = {
  firstContent: FirstContentEntity,
  editorialReview: EditorialReviewEntities.EditorialReview,
  whatsNewContent: VehicleComparisonsEntities.WhatsNewContent,
  className: PropTypes.string,
  headingClassName: PropTypes.string,
  HeadingTag: PropTypes.string,
  sectionsToRender: PropTypes.arrayOf(PropTypes.oneOf(Object.values(HIGHLIGHTS_NAME))),
  isPreprod: PropTypes.bool,
};

EditorialHighlightsListsUI.defaultProps = {
  firstContent: null,
  editorialReview: null,
  whatsNewContent: null,
  className: null,
  headingClassName: 'heading-4 mb-0_5',
  sectionsToRender: [
    HIGHLIGHTS_NAME.PROS,
    HIGHLIGHTS_NAME.CONS,
    HIGHLIGHTS_NAME.WHATS_NEW,
    HIGHLIGHTS_NAME.WHAT_TO_EXPECT,
  ],
  HeadingTag: 'div',
  isPreprod: false,
};

export const propsAreEqual = (prevProps, { editorialReview, firstContent }) => !(editorialReview || firstContent);
export const EditorialHighlightsLists = connectToModel(React.memo(EditorialHighlightsListsUI, propsAreEqual), {
  whatsNewContent: bindToPath(
    ({ params, sectionsToRender }) =>
      (!sectionsToRender || sectionsToRender.includes(HIGHLIGHTS_NAME.WHATS_NEW)) && buildWhatsNewPath(params),
    VehicleDetailsModel
  ),
});
