import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { SCROLL_OFFSET, SCROLL_DURATION } from 'site-modules/shared/constants/sub-navigation';
import { getChildren, getContentFreshcoreAd } from 'site-modules/shared/utils/article-helper';
import { FirstContentArticleEntity } from 'client/data/models/editorial-review';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';

export function FirstContentArticles({
  articles,
  isMobile,
  noCollapse,
  suppressFirstMedia,
  isUsed,
  isFreshcoreAdDisabled,
}) {
  if (!get(articles, 'length')) {
    return null;
  }

  const contentFreshcoreAd = getContentFreshcoreAd(isMobile);

  return articles.map((article, index) => {
    const isFirstArticle = index === 0;
    const isLastArticle = index === articles.length - 1;
    const key = `article-${index}`;
    const hideDate = !isUsed && !isFirstArticle;

    if (articles.length === 1 && !isFreshcoreAdDisabled) {
      article.content.splice(contentFreshcoreAd.entry, 0, contentFreshcoreAd);
    }

    const content = getChildren({ article, key, suppressMedia: !index && suppressFirstMedia, hideDate });

    return (
      <div className="article" key={key}>
        <ScrollElement id={`scrollTop-related-${key + 1}`} />
        {noCollapse ? (
          content
        ) : (
          <ContentCollapse
            minHeight="35rem"
            isEclipseFade={false}
            showBtnBelow
            btnClasses="px-0_5 py-0 size-16 text-primary-darker text-capitalize"
            btnContainerClasses="mt-0_5"
            btnTextClasses="font-weight-normal"
            justify="justify-content-start"
            resetParams={{
              offset: SCROLL_OFFSET,
              duration: SCROLL_DURATION,
              to: `scrollTop-related-${key + 1}`,
            }}
          >
            {content}
          </ContentCollapse>
        )}
        {!isLastArticle && <hr className="mt-1_5 mb-2" />}
      </div>
    );
  });
}

FirstContentArticles.propTypes = {
  articles: PropTypes.arrayOf(FirstContentArticleEntity),
  isMobile: PropTypes.bool,
  noCollapse: PropTypes.bool,
  suppressFirstMedia: PropTypes.bool,
  isUsed: PropTypes.bool,
  isFreshcoreAdDisabled: PropTypes.bool,
};

FirstContentArticles.defaultProps = {
  articles: null,
  isMobile: false,
  noCollapse: false,
  suppressFirstMedia: false,
  isUsed: false,
  isFreshcoreAdDisabled: false,
};
