import React, { Fragment, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import classnames from 'classnames';
import { getFullImageUrl, getStaticImageUrl } from 'client/utils/image-helpers';
import { IMAGE_SIZES } from 'site-modules/shared/constants/editorial/editorial';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { AdUnit } from 'site-modules/shared/components/ad-unit/ad-unit';
import { EditorialHeroCarouselWrapper } from 'site-modules/shared/components/editorial/editorial-hero-carousel-wrapper/editorial-hero-carousel-wrapper';
import { Video } from 'site-modules/shared/components/video/';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ArticleTitle } from 'site-modules/shared/components/editorial/article-title/article-title';
import { AdSlide } from './ad-slide/ad-slide';
import './editorial-hero-carousel.scss';

function renderPhotoSlide(href, counter, title, isMobile, cta, size) {
  const imageSize = isMobile ? IMAGE_SIZES.lg : size;
  const imageUri = getFullImageUrl(href, imageSize);

  return (
    <div
      key={`${href}-${counter}`}
      className={classnames('slide', { 'd-flex justify-content-center': !isMobile, 'd-block': isMobile })}
      data-index={counter}
    >
      {!!title && (
        <div className="editorial-carousel-title-wrapper w-100 h-100 d-flex has-link align-items-start">
          <ArticleTitle title={title} tag="span" />
        </div>
      )}
      <img className={classnames('photo', { 'my-auto': !isMobile })} alt={title || ''} src={imageUri} />
      {!!get(cta, 'ctaText') && (
        <div className="cta-overlay w-100">
          <Container>
            <Row>
              <Col xs={12} md={6} lg={4} className="overlay-block py-1_5 px-1_25 text-center text-md-left">
                <ContentFragment classes="cta-text" componentToUse="p">
                  {cta.ctaText}
                </ContentFragment>
                <a
                  className="d-inline-block text-uppercase h7 font-weight-bold cta-link hidden-sm-down"
                  href={get(cta, 'ctaLink.url')}
                >
                  {get(cta, 'ctaLink.name')} <i className="icon icon-arrow-right3" aria-hidden />
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
}

function renderVideoSlide(videoId, counter, type) {
  return (
    <div key={`${videoId}-${counter}`} className="video-slide" data-index={counter}>
      <Video containerClassName="video" videoId={videoId} type={type} />
    </div>
  );
}

function renderSlide({ href, title, rel, cta }, counter, isMobile, imageSize) {
  switch (rel) {
    case 'video_youtube':
      return renderVideoSlide(href, counter, 'Youtube');
    default:
      return renderPhotoSlide(href, counter, title, isMobile, cta, imageSize);
  }
}

function getSlides(links, adContent, isMobile, imageSize) {
  if (!(links && links.length)) {
    return [];
  }

  const slides = links.map((slideContent, idx) => renderSlide(slideContent, idx, isMobile, imageSize));
  if (adContent && adContent.hasChild('hero-ad-content')) {
    const adImageUri = getStaticImageUrl(
      adContent
        .child('hero-ad-content')
        .metadata('adimage')
        .value()
    );
    slides.push(
      <AdSlide
        key={`${adImageUri}-${links.length}`}
        adContent={adContent.child('hero-ad-content')}
        isMobile={isMobile}
        adImageUri={adImageUri}
        counter={links.length}
      />
    );
  }

  return slides;
}

export function EditorialHeroCarousel({ slides, adContent, isMobile, imageSize, children, classes, carouselClasses }) {
  const [isTitleVisible, setTitleVisible] = useState(true);
  const renderedSlides = useMemo(
    () => getSlides(slides, adContent, isMobile, imageSize),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slides, adContent]
  );

  const setCurrentSlide = useCallback(
    (from, to) => {
      setTitleVisible(renderedSlides[to].type !== AdSlide);
    },
    [renderedSlides]
  );

  if (!renderedSlides.length) {
    return null;
  }

  return (
    <div className="editorial-hero-carousel-container">
      {!!children && (
        <div
          className={classnames('editorial-carousel-title-wrapper w-100 h-100 d-flex', classes, {
            'hidden-xs-up': !isTitleVisible,
          })}
        >
          {children}
        </div>
      )}

      {renderedSlides.length === 1 ? (
        <div className="editorial-hero-carousel hero-carousel" data-tracking-parent="edm-entry-editorial-hero-carousel">
          {renderedSlides[0]}
        </div>
      ) : (
        <EditorialHeroCarouselWrapper
          slides={renderedSlides}
          setCurrentSlide={setCurrentSlide}
          carouselClasses={carouselClasses}
        />
      )}

      {adContent.hasChild('hero-ad-content') && (
        <Fragment>
          <AdUnit
            adName="hero"
            showLabel={false}
            position={'1'}
            md
            lg
            xl
            refreshable={false}
            renderWhenViewable={false}
          />
          <AdUnit
            adName="mhero"
            showLabel={false}
            position={'1'}
            xs
            sm
            refreshable={false}
            renderWhenViewable={false}
          />
        </Fragment>
      )}
    </div>
  );
}

EditorialHeroCarousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
      cta: PropTypes.shape({
        ctaText: PropTypes.string,
        ctaLink: PropTypes.shape(),
      }),
    })
  ),
  adContent: CmsEntities.Content,
  children: PropTypes.node,
  classes: PropTypes.string,
  carouselClasses: PropTypes.string,
  isMobile: PropTypes.bool,
  imageSize: PropTypes.number,
};

EditorialHeroCarousel.defaultProps = {
  slides: [],
  adContent: DEFAULT_CONTENT,
  children: '',
  classes: '',
  carouselClasses: '',
  isMobile: false,
  imageSize: IMAGE_SIZES.max,
};
